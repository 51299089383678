.font-heading {
    font-family: var(--heading-font);
}

.font-code {
    font-family: var(--mono-font);
}

*:focus-within.\:focusin\:w-full {
    width: 100%;
}
