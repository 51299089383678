.spinner {
    svg {
        width: 100%;
    }
}

.font-heading {
    font-family: 'Young' 'Roboto', sans-serif;
    font-weight: 500;
}

.mdc-tab-body-wrapper {
    flex-grow: 1;
}

form {
    label[for] {
        font-size: 0.8em;
        font-weight: 500;
        margin: 0.5em 0;

        &.error {
            color: var(--error);
        }

        > span:not(.mat-checkbox-label) {
            color: var(--pending);
        }
    }

    .fieldset {
        display: flex;
        flex-wrap: wrap;

        > * + * {
            margin-left: 1rem;
        }
    }

    .field {
        position: relative;
        display: flex;
        flex: 1 1 0%;
        flex-direction: column;
        width: 100%;
        min-width: 20%;

        > * + * {
            margin-bottom: 0.25rem;
        }
    }

    .field div.error {
        position: absolute;
        bottom: 0.5rem;
        left: 1rem;
        color: var(--error);
        font-size: 0.75rem;
        font-weight: 400;
    }

    settings-form-field {
        margin-bottom: 1.5em;
    }

    mat-checkbox {
        margin: 0.25em;
    }
}

#placeos {
    [confirm-modal] {
        p {
            margin-bottom: 0.5rem;
        }
    }
}

.panel {
    .mdc-icon-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        width: 2em;
        font-size: 1em;

        &.action {
            background-color: #fff;
            border: 2px solid #000;
            box-shadow: 0 0 0 2px #fff;
            color: rgba(#000, 0.8);
            height: 2em;
            width: 2em;
        }

        .mdc-button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        app-icon {
            font-size: 1em;
        }
    }
}

.overlay-backdrop {
    background-color: rgba(#000, 0.6);
}

[bg='white'] .mdc-form-field-outline {
    background-color: #fff;
}

.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper
        .mat-mdc-form-field-flex
        .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
        top: 22px;
    }

    &.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
        .mdc-notched-outline__notch {
        border-right: none;
    }

    label {
        width: auto;
    }
}

.mdc-dialog__container {
    padding: 0;

    header {
        display: flex;
        align-items: center;
        padding: 0.5em 1em;
        width: 100%;
        border-bottom: 1px solid var(--b2);

        h1,
        h2,
        h3,
        h4 {
            flex: 1;
            min-width: 50%;
        }

        > * {
            margin: 0;
        }
    }

    .mdc-dialog-content {
        margin: 0;
        padding: 0;
        .body {
            padding: 1em;
        }
    }

    .mdc-dialog-actions {
        margin-bottom: 0;
        footer {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

[notes] {
    a {
        color: #3344dd;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
            color: #bb1122;
            text-decoration: none;
        }

        &:visited {
            color: #884488;
        }
    }
}

mat-form-field {
    &.mdc-form-field-appearance-outline.mdc-focused {
        .mdc-form-field-outline-thick {
            color: var(--p);
        }
    }

    &.map,
    &.white {
        .mdc-form-field-wrapper {
            padding: 0;
        }

        .mdc-form-field-outline {
            background-color: #fff;
            border-radius: 4px;
        }
    }
}

[hide-outline] {
    .mdc-form-field-wrapper {
        padding: 0;
    }
}

custom-table {
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

mat-form-field {
    &.no-subscript .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

.mdc-form-field-infix {
    border-top: none;
}

.mdc-select-panel {
    .mdc-option {
        &.mdc-selected {
            &:not(.mdc-option-disabled) {
                color: var(--p);
            }
        }
    }
}

mat-select {
    .mdc-select-trigger {
        .mdc-select-arrow-wrapper {
            transform: none;
        }
    }
}

.mdc-simple-snackbar {
    button.mdc-button {
        background: none;
        border: none;
    }
}

.mdc-snack-bar-container {
    &.success {
        background-color: var(--su);
        color: #fff;
    }
    &.warn {
        background-color: var(--wa);
        color: #fff;
    }
    &.error {
        background-color: var(--er);
        color: #fff;
    }
    &.info {
        background-color: #37474f;
        color: #fff;
    }
}

.mdc-form-field {
    &.visible {
        .mdc-select-value {
            overflow: visible;
        }
    }

    .mdc-form-field-subscript-wrapper {
        overflow: visible;
    }

    .mdc-form-field-wrapper {
        .mdc-form-field-label {
            top: 2em;
            margin-top: -0.1em;
        }
    }

    &[overlay],
    &[overlay]:hover {
        .mdc-form-field-outline {
            background-color: #fff;
            border-radius: 4px;
        }
    }

    &.rounded,
    &.rounded:hover {
        .mdc-form-field-outline {
            border-radius: 2em;
        }
        .mdc-form-field-outline-start {
            border-radius: 2em 0 0 2em;
            min-width: 2em;
        }
        .mdc-form-field-outline-end {
            border-radius: 0 2em 2em 0;
        }
    }

    .mdc-form-field-should-float .mdc-form-field-wrapper {
        .mdc-form-field-label {
            transform: translateY(-1.5em) scale(0.75) !important;
        }
    }
}

.mdc-form-field {
    &[overlay],
    &[overlay]:hover {
        .mdc-form-field-outline {
            background-color: var(--n);
            border-radius: 4px;
        }
    }
}

mat-slider {
    &[white] {
        &.mdc-slider-min-value:not(.mdc-slider-thumb-label-showing)
            .mdc-slider-thumb {
            border-color: rgba(255, 255, 255, 0.26);
        }

        .mdc-slider-track-background {
            background-color: rgba(255, 255, 255, 0.26);
        }
    }

    .mdc-slider-thumb {
        background-color: var(--p);
    }

    .mdc-slider-track-fill {
        background-color: var(--p);
    }
}

mat-form-field {
    &.mdc-form-field-appearance-outline.mdc-focused {
        .mdc-form-field-outline-thick {
            color: var(--p);
        }
    }
}

[hide-outline] {
    mat-form-field {
        .mdc-form-field-outline > * {
            border-color: rgba(0, 0, 0, 0);
        }
        &:hover .mdc-form-field-outline > *,
        &.mdc-focused .mdc-form-field-outline > * {
            border-color: rgba(0, 0, 0, 0.5);
        }
    }
}

[no-wrapper] {
    mat-form-field {
        .mdc-form-field-wrapper {
            padding: 0;
            margin: 0;
        }
    }
}

.mdc-button-toggle-button[aria-pressed='true'] {
    background-color: var(--p);
    color: var(--pc);
}

.mdc-chip.mdc-standard-chip {
    &.mdc-primary {
        color: var(--p);
    }
    &.mdc-accent {
        background-color: var(--wa);
    }
}

.mdc-spinner {
    circle {
        stroke: var(--p);
    }
}

.mdc-progress-bar-fill {
    &::after {
        background-color: var(--p);
    }
}

div.mdc-menu-panel {
    max-width: calc(100vw - 1em);
    .desk {
        line-height: 48px;
        padding-left: 8px;
        padding-right: 8px;
        span {
            font-weight: 400;
            text-transform: capitalize;
        }
    }
}

.mdc-tooltip {
    font-size: 0.8em;
    background-color: rgba(#212121, 0.85);
}

.mdc-table {
    td.mdc-cell:last-of-type {
        padding-right: 0;
    }
}

a-date-picker {
    .date.active {
        background-color: var(--p) !important;
    }
    .dot {
        background-color: var(--p) !important;
    }
}

.chevron {
    position: relative;

    > div {
        position: relative;
        top: -2px;
        height: 12px;
        width: 12px;
        transform: rotate(45deg);
        transition: transform 200ms, top 200ms;
        border-bottom: 3px solid var(--bc);
        border-right: 3px solid var(--bc);
    }

    &.upside {
        > div {
            top: 2px;
            transform: rotate(225deg);
        }
    }
}

form {
    mat-form-field,
    a-date-field,
    an-action-field,
    editor {
        flex: 1;
        min-width: 50%;
    }
}

[disabled] {
    .chevron {
        opacity: 0.35;
    }
}

.mdc-menu-content {
    .legend {
        padding-left: 8px;
        display: flex;
        align-items: center;
        line-height: 2em;
        .color {
            border-radius: 50%;
            height: 10px;
            width: 10px;
            margin-right: 5px;
        }
    }
}

[qr-code] {
    font-size: 6vmin;

    h2 {
        font-size: 1.5em;
        margin: 0;
        padding: 0;
    }

    span {
        font-weight: 500;
    }

    &.space-y-2 > * + * {
        margin-top: 0.5em;
    }

    img {
        height: 70vmin;
        width: 70vmin;
    }
}

custom-table[asset-requests],
custom-table[purchase-orders] {
    cdk-row {
        background-color: var(--b1);
        [cdk-cell]:not(:first-child) {
            border-left: 1px solid var(--b2);
        }
    }
    cdk-header-row {
        background: var(--b3) !important;
        [cdk-header-cell] {
            background: none;
        }
    }
}

custom-table[asset-view] {
    border: 1px solid #ccc;
    cdk-row {
        background-color: #fff;
        &:hover {
            background-color: #0001;
        }
        &:nth-child(2n) {
            background-color: #f6f6f6;
        }
    }

    [cdk-cell],
    [cdk-header-cell] {
        min-width: 2.5rem;
    }

    cdk-header-row {
        background: #d0d0d0;
        [cdk-header-cell] {
            background: none;
        }
    }
}

custom-table[header-dark] {
    cdk-header-row {
        background: var(--n) !important;
        color: var(--nc);
        [cdk-header-cell] {
            background: none;
        }
    }
}

button[btn]:not([icon]),
a[btn] {
    --color: var(--s);
    background-color: var(--color);
    border: 1px solid var(--color);
    color: var(--sc);
    font-size: 1rem;
    min-height: 3em;
    padding: 0 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;

    &.success {
        --color: var(--su);
    }

    &.clear {
        background: none;
        border: none;
        color: var(--bc);
    }

    &.error {
        --color: var(--er);
        color: var(--erc);
    }

    &.black {
        --color: var(--bc);
    }

    &.inverse {
        background: none;
        color: var(--color);
        border-color: currentColor;
    }

    &[disabled] {
        background-color: var(--n);
        color: var(--nc);
        border-color: var(--n);
        pointer-events: none;
        opacity: 0.3;
    }
}

#placeos .mdc-icon-button,
button[icon],
a[icon] {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5em;
    width: 2.5em;
    min-width: 2.5em;
    font-size: 1em;
    padding: 0;
    border-radius: 9999px;

    .mdc-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    app-icon {
        font-size: 1.5em;
    }

    &[disabled]:not([disabled='false']) {
        opacity: 0.3;
        pointer-events: none;
    }
}

.white {
    .mdc-text-field {
        background: #fff;
    }
}

#placeos {
    .mdc-text-field--outlined {
        padding: 0 1rem;
    }

    [no-x-scroll] {
        .cdk-virtual-scroll-content-wrapper {
            width: 100%;
        }
    }

    .mat-mdc-menu-panel {
        background-color: var(--b1);
        color: var(--bc);
    }

    .mat-mdc-menu-item {
        color: var(--bc);

        &:hover {
            background-color: var(--b2);
        }
    }

    .mat-mdc-tab-list {
        border-bottom: 1px solid var(--b2);
    }

    .mat-mdc-form-field-infix {
        padding: 0.75rem 0;
        min-height: 2rem;
    }

    .cdk-overlay-pane {
        max-width: none !important;
    }

    .svg-viewer__label {
        top: -200%;
        left: -200%;
        line-height: 1.1;
    }

    .mdc-datepicker-toggle {
        button {
            position: relative;
            margin-top: -1rem;
            top: 0.25rem;
        }
    }

    mat-slide-toggle {
        .mdc-slide-toggle-bar {
            height: 24px;
            border-radius: 12px;
        }

        .mdc-slide-toggle-thumb-container {
            top: 2px;
            left: 2px;
        }

        &.mdc-slide-toggle.mdc-checked .mdc-slide-toggle-thumb-container {
            transform: translate3d(12px, 0, 0);
        }
    }

    mat-pseudo-checkbox.mdc-pseudo-checkbox-checked {
        background: var(--p);
    }

    mat-checkbox {
        .mdc-checkbox__background {
            border-color: var(--n);
            svg {
                color: var(--inc);
            }
        }

        .mdc-label {
            font-weight: normal;
            margin: 0;
        }

        &.mat-mdc-checkbox-checked,
        &[ng-reflect-indeterminate='true'] {
            .mdc-checkbox__background {
                border-color: var(--in);
                background-color: var(--in);
            }
        }
    }

    .mdc-tab__text-label {
        color: var(--bc);
    }

    .mdc-tab-indicator__content {
        border-color: var(--s);
    }

    [aria-selected='true'] {
        .mdc-tab__text-label {
            color: var(--s);
        }
    }

    .mat-mdc-paginator {
        background-color: var(--b2);
        color: var(--bc);

        .mat-mdc-button-base * {
            color: var(--bc);
            border-color: var(--bc);
            fill: var(--bc);
        }
    }

    .mdc-button__label {
        color: inherit;
    }

    .mdc-ink-bar {
        background-color: var(--p);
    }

    mat-chip {
        &[color='internal'] {
            color: var(--pc);
            background-color: var(--p);
        }
        &[color='external'] {
            color: var(--wac);
            background-color: var(--wa);
        }
    }

    .mdc-button-toggle {
        background: inherit;
        color: currentColor;
    }

    mat-bottom-sheet-container {
        padding: 0;
    }

    .mdc-list-item__primary-text {
        display: block;
    }

    .mat-expansion-panel {
        background-color: var(--b2);
        color: var(--bc);
    }

    .mat-mdc-tab-header-pagination-chevron {
        border-color: var(--bc);
    }

    .mat-expansion-panel-header-title {
        color: var(--bc);
    }

    .mat-expansion-indicator::after {
        color: var(--bc);
    }

    .mat-mdc-form-field-icon-prefix > * {
        position: relative;
        top: 0;
    }

    .mat-mdc-form-field-icon-suffix {
        margin-right: -0.25rem;
        & > * {
            position: relative;
            top: 0;
        }
    }

    .flex {
        .mdc-list-item__primary-text {
            display: flex;
            align-items: center;
        }
    }

    mat-tab-group {
        .mdc-tab-label-container {
            background-color: var(--n);
        }
        .mdc-tab-label-content {
            color: var(--bc);
        }
    }

    .mdc-datepicker-toggle button {
        color: var(--bc);
    }

    nav {
        .mdc-tab-links {
            background-color: var(--b2);
        }
        .mdc-tab-link {
            color: var(--bc);
        }
    }

    .mdc-select-value-text,
    .mdc-select-arrow {
        color: var(--b1);
    }

    .mdc-form-field-outline > div {
        color: var(--b2);
    }

    .mdc-form-field {
        ::placeholder,
        .mdc-select-placeholder {
            color: var(--b2);
        }
    }

    button.mdc-button,
    a[button] {
        color: var(--bc);

        &.inverse {
            background: none;
            --color: var(--b1);
        }

        &.error {
            --color: var(--er);
        }

        &[disabled] {
            opacity: 0.3;
        }
    }

    .mdc-slide-toggle-bar {
        background-color: rgba(255, 255, 255, 0.2);
    }

    .mdc-checked .mdc-slide-toggle-bar {
        background-color: rgba(255, 255, 255, 0.4);
    }

    .mdc-menu-surface {
        background-color: var(--b1);

        .mdc-list-item__primary-text {
            color: var(--bc);
        }

        .mdc-list-item--selected {
            .mdc-list-item__primary-text {
                color: var(--s);
            }
        }
    }

    mat-pseudo-checkbox {
        color: var(--bc);
        border-color: var(--n);
        &::after {
            color: var(--bc);
        }

        &.mat-pseudo-checkbox-checked {
            border-color: var(--s);
            background-color: var(--s);
            color: var(--sc);
        }
    }

    .mdc-menu-item {
        color: var(--bc);
        &:hover {
            background-color: var(--b3);
        }

        mat-pseudo-checkbox {
            color: var(--s);
            background-color: none;
            border-color: none;
            &::after {
                color: var(--s);
            }
        }
    }

    .mat-mdc-option {
        mat-pseudo-checkbox {
            color: var(--s);
            background-color: #0000;
            border-color: #0000;
            &::after {
                color: var(--s);
            }
        }
    }

    mat-dialog-container,
    .mdc-dialog__surface {
        background-color: var(--b1);
        color: var(--bc);
    }

    .mdc-label {
        color: var(--bc);
    }

    mat-bottom-sheet-container {
        background-color: var(--b1);
        color: var(--bc);
    }

    .mdc-paginator {
        background-color: inherit;
        color: inherit;
    }

    mat-spinner {
        svg {
            stroke: var(--s);
        }
    }

    .mdc-radio-outer-circle,
    .mdc-radio__outer-circle {
        border-color: var(--b3);
    }

    .mdc-radio-checked,
    .mat-mdc-radio-checked {
        .mdc-radio-outer-circle,
        .mdc-radio__outer-circle {
            border-color: var(--s);
        }
        .mdc-radio-inner-circle,
        .mdc-radio__inner-circle {
            border-color: var(--s);
        }
    }

    .mat-mdc-select-value,
    .mat-mdc-select-arrow {
        color: var(--bc);
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
        border-color: var(--n);
    }

    .mdc-text-field__input {
        color: var(--bc);
    }

    .mdc-text-field--focused {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing {
            border-color: var(--s);
        }
    }

    .mdc-autocomplete-panel {
        background-color: var(--n);
    }

    .mdc-ink-bar {
        background-color: var(--bc);
    }

    .ql-toolbar {
        .ql-stroke {
            stroke: var(--n);
        }
        .ql-picker-label::before {
            color: var(--bc);
        }
    }
    .ql-blank::before {
        color: var(--n);
    }

    .mdc-menu-panel,
    .mdc-select-panel {
        background-color: var(--n);
    }

    .mdc-option-text {
        color: var(--bc);
    }

    mat-option.mdc-active {
        background-color: var(--b1);
    }

    .mdc-active .mdc-option-text {
        color: var(--s);
    }

    .mdc-option:hover {
        background-color: var(--sc);
    }

    bootstrap,
    [app-bootstrap] {
        background-color: var(--n);
    }

    mat-snack-bar-container {
        button.mdc-button {
            color: inherit;
        }
        .mdc-snackbar__label {
            color: inherit;
        }
        &.success {
            .mdc-snackbar__surface {
                background-color: var(--su);
                color: var(--suc);
            }
        }
        &.info {
            .mdc-snackbar__surface {
                background-color: var(--in);
                color: var(--inc);
            }
        }
        &.error {
            .mdc-snackbar__surface {
                background-color: var(--er);
                color: var(--erc);
            }
        }
        &.warning,
        &.warn,
        &.pending {
            .mdc-snackbar__surface {
                background-color: var(--wa);
                color: var(--wac);
            }
        }
    }

    .mat-mdc-card.mdc-card {
        border: 1px solid var(--b2);
        background-color: var(--b1);
        color: var(--bc);
    }

    [mat-menu-item]:hover {
        background-color: var(--b2);
    }

    [mat-menu-item] span {
        display: flex;
        align-items: center;
        width: full;
        @apply space-x-2;
    }
}

.markdown {
    h1 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        margin-top: 0.5rem;
        font-weight: 500;
    }
    h2,
    h3 {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
    }
    ul {
        list-style: initial;
        padding-left: 1.5rem;
        margin-bottom: 0.5rem;
    }
}

mat-list-option.mdc-list-item--selected {
    border-right: 3px solid rgba(#212121, 0.85);
}

#placeos .sv-question {
    overflow-x: hidden;
}

.ct-label {
    color: var(--bc);
}

.progress-bar {
    width: 100%;
    border-radius: 5px;
}

.progress-bar-fill {
    display: block;
    border-radius: 5px;
}

mat-form-field {
    &.no-subscript .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

[role='table'] {
    overflow: auto;
}

[table-head] {
    position: sticky;
    top: 0;
    z-index: 50;
    display: flex;
    align-items: center;
    background-color: var(--b3);
    border: 1px solid var(--b2);
    @apply divide-x divide-base-200 font-medium;

    > div {
        display: flex;
        align-items: center;
        height: 2.75rem;
    }
}

[role='table'] [body],
[table-body] {
    @apply border border-base-200;
    border-top: none;
}

[table-row] {
    @apply flex items-center border-b border-base-200 divide-x divide-base-200 hover:bg-base-200;
    height: 3.25rem;

    &:last-child {
        border-bottom: none;
    }

    > div {
        @apply flex items-center overflow-hidden;
        flex-shrink: 0;
        height: 3.25rem;
        min-width: 2rem;
        user-select: text;
    }
}

.info-block {
    @apply w-full p-16 flex flex-col items-center justify-center opacity-30;
}
