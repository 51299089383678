input,
textarea {
    user-select: text;
    background: none;
}

.underline > a:not([btn]),
a:not([btn]).underline {
    color: var(--in);
    &:hover {
        color: var(--er);
    }
}

.theme-dark {
    input {
        color-scheme: dark;
    }
    input::placeholder {
        color: inherit;
        opacity: 0.6;
    }
}

div > label,
section > label {
    font-size: 0.8rem;
    font-weight: 500;
    span {
        color: var(--pending);
    }
}

:not(.mat-form-field-label-wrapper) > label {
    width: 100%;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
    font-weight: bold;

    > span:first-child {
        color: hsl(var(--wa));
    }

    &.error {
        color: hsl(var(--er));
    }
}

#placeos {
    .svg-viewer__label {
        min-width: 12rem;
    }

    ::placeholder,
    .mat-form-field-label,
    .mat-mdc-select-placeholder {
        color: var(--n);
    }

    input {
        caret-color: var(--p);
    }
}

table {
    width: 100%;

    .duo {
        > * {
            display: inline-block;
        }
    }
}

table:not([role='grid']) {
    thead {
        opacity: 0.85;
        background-color: var(--b3);
    }

    tr {
        width: 100%;
        &:nth-child(2n) {
            background-color: var(--b2);
        }

        &:hover {
            background-color: var(--b3);
        }

        &.cdk-drag-preview {
            background-color: var(--b1);
            border-color: var(--n);
            border-radius: 4px;
            @apply shadow;
        }
    }

    td {
        border-color: var(--n);
        padding: 0.25em 0.5em;

        &.small {
            width: 2em;
            text-align: center;
            vertical-align: middle;
        }
    }
}

code {
    padding: 0.125rem 0.25rem;
    background-color: var(--b2);
    border-radius: 4px;
    font-size: 0.75rem;
    @apply truncate;
}
