@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/Roboto-Light.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/Roboto-Medium.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/Roboto-Bold.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/Roboto-Black.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('./fonts/Roboto-BlackItalic.ttf');
}

@font-face {
    font-family: 'Young';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/Young.ttf');
}

@font-face {
    font-family: 'Young';
    font-style: italic;
    font-weight: 300;
    src: url('./fonts/Young-italic.ttf');
}
